import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Modal, Button, ModalFooter, Card, CardBody, CardTitle, CardText } from "reactstrap";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import $ from "jquery"
import Login from "../login";
import Register from "../register";
import AsidePurchase from "../asidePurchase";
import "./header.scss";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/actions/user.actions";
import { metaPixelService } from '../../services/metaPixel.service'
import { setModal } from "../../store/actions/user.actions";

const Header = (props) => {
    const isModalOpen = useSelector((state) => state.user.isModalOpen);
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const [activeAside, setStateAside] = useState("");
    function AsidePurchaseBox() {
        if (activePurchase != "") {
            setStateAside(activeAside === "" ? " is-show" : "");
        } else {
            setStatusPurchase(true);
        }
    }
    const [modalShow, setModalShow] = useState(true);

    const [activePurchase, setStatePurchase] = useState("");
    const setStatusPurchase = (status) => {
        setStatePurchase(status ? " active_purchase" : "");
    };

    const [btnMenu, setBtnMenu] = useState("fa-bars");
    const [menuMobile, setMenuMobile] = useState("");
    function MenuMobile() {
        setMenuMobile(menuMobile === "" ? " is-visible" : "");
        setBtnMenu(btnMenu === "fa-bars" ? " fa-times" : "fa-bars");
    }

    //const [modal, setModal] = useState(false);
    const toggle = () => dispatch(setModal());

    const [showLogeo, setShowLogeo] = useState(false);
    const toggleLogeo = () => setShowLogeo(!showLogeo);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDrop = () => setDropdownOpen((prevState) => !prevState);

    const CargarLogeo = () => {
        return showLogeo === false ? (
                <div>
                    <h6 className="fw400 ttls cred">¿Eres nuevo en Red Godínez?</h6>
                    <div>
                        <button className="btn" onClick={toggleLogeo}>Regístrate aquí</button>
                    </div>
                </div>
            ) :
            (
                <div>
                    <h6 className="fw400 ttls cred">¡Únete YA, <br /> compra todo lo que quieras y ahorra!</h6>
                    <div>
                        <button className="btn" onClick={toggleLogeo}>Entra aquí</button>
                    </div>
                </div>
            );
    };

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        setStatusPurchase(localStorage["pruchaseTotal"] != 0);
    });

    useEffect(() => {
        if (!user.activeSubscription && user.isAuthenticated && activePurchase && history.location.pathname == "/active-aside-purchase")
            setStateAside(" is-show");
    }, [user, activePurchase]);


    const logOff = () => {
        fetch("/api/Account/LogOff", { method: "POST" })
            .then(function (response) {
                if (response.ok) {
                    sessionStorage.clear();
                    return response.json();
                }
            })
            .then(function (result) {
                if (result.Status) {
                    this.dispatch(getUser());
                    toggleDrop();
                    history.push("/");
                } else {
                    console.log(result);
                }
                window.location.reload();
            })
            .catch(function (error) {
                window.location.reload();
                console.log(error);
            });
    };

    const handleClose = () => {
        setModalShow(false)
    }

    return (
        <header className="wrapper_header">
            <div className="box_head_top fw300">
                <div className="container_provider">
                    <div className="content_one">
                        <span>
                            <Link
                                to="#"
                                className=""
                                onClick={(e) => {
                                    metaPixelService.simpleEvent('track', 'Contact');
                                    window.location.href = "mailto: " + props.MailContact;
                                    e.preventDefault();
                                }}
                            >
                                <i className="fas fa-envelope"></i> {props.MailContact}
                            </Link>
                        </span>
                    </div>
                    <div className="content_two">
                        {/* <a href="/#embajadores">¿Cómo ganar a través de<span> Red Godínez</span></a> */}
                        Síguenos
                        <span>
                            <a
                                target="_blank"
                                href="https://www.facebook.com/Red-God%C3%ADnez-112619633741809"
                            >
                                <i className="fab fa-facebook-square"></i>
                            </a>
                        </span>
                    </div>
                </div>
            </div>

            <div className="box_head_content">
                <div className="container_provider box_flex align_center justify_between">
                    <div className="box_brand_logo">
                        <Link to="/">
                            <img src="./imgs/rgodinez_clrs.png" alt="RedGodínez" />
                        </Link>
                    </div>

                    <div className={`box_content_options trans ${menuMobile}`}>
                        <div className="content_opt_a trans"></div>
                        <div className="content_opt_b trans">
                            <Link className="link_options dsktp" to="/">
                                <span>
                                    <i className="fas fa-th-large"></i>
                                </span>
                                <p className="text_link">Escritorio</p>
                            </Link>
                            {history.location.pathname != "/checkout" && (
                                <span
                                    className={`link_options${activePurchase} dsktp`}
                                    onClick={AsidePurchaseBox}
                                    id="spanActivePurchase"
                                >
                                    <span className="ico_canasta ">
                                        <i class="fas fa-shopping-basket"></i>
                                    </span>
                                    <p className="text_link">Mi mochila </p>
                                </span>
                            )}

                            {user.isAuthenticated ? (
                                <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggleDrop}
                                    className="logeado link_options"
                                >
                                    <DropdownToggle
                                        tag="span"
                                        data-toggle="dropdown"
                                        aria-expanded={dropdownOpen}
                                        className="box_user_logeado"
                                    >
                                        <span className="ico_gafete">
                                            <img src="./imgs/ico_gafete.png" alt="ICO" />
                                        </span>
                                        <span className="conten_nameuser">
                                            <p className="hello_user">¡Hola!</p>
                                            <h6 className="name_user">{user.userName}</h6>
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <Link
                                            to="/shopping-history"
                                            className="dropdown-item"
                                            onClick={(toggleDrop, MenuMobile)}
                                        >
                                            <i className="fas fa-list-alt"></i> Mis compras
                                        </Link>
                                        <Link
                                            to="/cards"
                                            className="dropdown-item"
                                            onClick={(toggleDrop, MenuMobile)}
                                        >
                                            <i className="fas fa-list-alt"></i> Mis tarjetas
                                        </Link>
                                        <button className="dropdown-item" onClick={logOff}>
                                            <i className="fas fa-power-off"></i> Cerrar sesión
                                        </button>
                                    </DropdownMenu>
                                </Dropdown>
                            ) : (
                                <span className="link_options box_gafete" onClick={toggle}>
                                    <div
                                        className="text-center content_gafete"
                                        onClick={MenuMobile}
                                    >
                                        <p className="ttl_gafete">MI GAFETE </p>
                                        <p className="go">Iniciar sesión</p>
                                    </div>
                                    <span className="ico_gafete">
                                        <img src="./imgs/ico_gafete.png" alt="ICO" />
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>

                    <div className="box_options_mobile">
                        <Link className="link_options dsktp" to="/">
                            <span>
                                <i className="fas fa-th-large"></i>
                            </span>
                            <p className="text_link">Home</p>
                        </Link>
                        {history.location.pathname != "/checkout" && (
                            <span
                                className={`link_options${activePurchase}`}
                                onClick={AsidePurchaseBox}
                                id="spanActivePurchase"
                            >
                                <span className="ico_canasta ">
                                    <i className="fas fa-shopping-basket"></i>
                                </span>
                                <p className="text_link">Mi mochila </p>
                            </span>
                        )}

                        <span className="btn_mobile" onClick={MenuMobile}>
                            <i className={`fas ${btnMenu}`}></i>
                        </span>
                    </div>
                </div>
            </div>

            <Modal isOpen={isModalOpen} toggle={toggle} className="wrapper_logeo">
                <div className="box_content_logeo">
                    <div className="content_info is_badge">
                        <div className="box_img_userlogin">
                            <img
                                src="./imgs/user_login.png"
                                alt="Inicia sesión en Red Godinez y descubre nuestras promociones"
                            />
                        </div>
                        <div>
                            <h5 className="ttls cred fw700">Únete</h5>
                            <p className="ttls mb-4 p_minimun">
                                Al corporativo más Godín de México… con promos en artículos chidos y una cuponera <strong>que ni tu jefe tiene</strong>
                            </p>
                        </div>
                        <CargarLogeo />
                    </div>

                    {showLogeo === false ? <Login toggle={toggle} /> : <Register />}
                    <span className="btn_close_modal trans" onClick={toggle}>
                        <i className="fas fa-times-circle"></i>
                    </span>
                </div>
            </Modal>

            <AsidePurchase
                toggleBlocking={props.toggleBlocking}
                activeAside={activeAside}
                onCloseClick={() => setStateAside("")}
            />

            <div className={`alert alert-warning alert-dismissible fade  ${modalShow ? 'show' : ''}`} role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25px" fill="#3d3d3d">
                    <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
                </svg>
                <p className="m-0 ml-2">Estamos presentando intermitencia en la entrega de nuestros vouchers, pero estamos trabajando para resolverlo pronto.</p> <a target="_blank" href="https://wa.me/5581050730" className="ml-2 text-white">Más información aquí</a>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </header>
    );
};

export default Header;
